.DailyShifts .lander {
    padding: 80px 0;
    text-align: center;
}

.DailyShifts .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.DailyShifts .lander p {
    color: #999;
}

.DailyShifts .shifts h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.DailyShifts .shifts p {
    color: #666;
}

.DailyShifts .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
}
@keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}

